<template>
  <div class="additionalSettlementPageBox page-info-content">
    <Details-page
      :details-title="'基本信息'"
      :detail-obj="detailObj"
      :details-item-arr="detailsItemArr"
    />
    <!-- 结算商品 -->
    <h3 class="page-subtitle-shade settlementTitle">
      &nbsp;结算商品
    </h3>
    <div class="table">
      <el-table :data="listData" border :max-height="432">
        <el-table-column prop="goodsName" label="品名" />
        <el-table-column prop="goodsMaterials" label="材质" />
        <el-table-column prop="goodsSpecs" label="规格" />
        <el-table-column prop="goodsProduct" label="产地" />
        <el-table-column prop="goodsWeights" label="件重(吨)" />
        <el-table-column prop="currSettleWeight" label="本次结算数量(吨)" width="180" />
        <el-table-column prop="custSettlePrice" label="结算单价(元/吨)" width="180">
          <template slot="header">
            <span>结算单价(元/吨)</span>
            <el-tooltip
              class="item"
              effect="dark"
              content="如有逾期费，则涵盖逾期费。"
              placement="top"
            >
              <i class="icon el-icon-warning" />
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="settleTotalPrice" label="结算金额(元)" width="180" />
        <!-- <el-table-column prop="custSettlePrice" label="结算单价(元/吨)" width="180" /> -->
      </el-table>
    </div>
    <!-- 确认信息 -->
    <h3 class="page-subtitle-shade settlementTitle">
      &nbsp;结算确认
      <span>按比率计息时，代采天数不满7天按7天计息</span>
    </h3>
    <el-descriptions
      class="confirmationSettlement"
      :column="2"
      :colon="false"
      label-class-name="labelClassName"
      size="medium"
    >
      <el-descriptions-item label="结算总数量(吨)">
        <el-input
          v-model="detailObj.settleTotalWeight"
          disabled
          placeholder="请输入"
        />
      </el-descriptions-item>
      <el-descriptions-item label="结算总货款(元)">
        <el-input
          v-model="detailObj.custSettleAmount"
          disabled
          placeholder="请输入"
        />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          已支付货款(元)
          <el-tooltip
            class="item"
            effect="dark"
            content="结算周期内提货出库数量对应货款"
            placement="top"
          >
            <i class="icon el-icon-warning" />
          </el-tooltip>
        </template>
        <el-input
          v-model="detailObj.paymentPaid"
          disabled
          placeholder="请输入"
        />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          已支付代采费(元)
          <el-tooltip
            class="item"
            effect="dark"
            content="结算周期内已支付代采费用"
            placement="top"
          >
            <i class="icon el-icon-warning" />
          </el-tooltip>
        </template>
        <el-input v-model="detailObj.agentCost" disabled placeholder="请输入" />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          货款差额(元)
          <el-tooltip
            class="item"
            effect="dark"
            content="货款差额=结算总货款-已支付货款-已付代采费"
            placement="top"
          >
            <i class="icon el-icon-warning" />
          </el-tooltip>
        </template>
        <el-input v-model="detailObj.custDiffGoodsAmount" disabled placeholder="请输入" />
      </el-descriptions-item>
      <el-descriptions-item label="可抵扣保证金(元)">
        <el-input
          v-model="detailObj.deductibleDepositAmount"
          disabled
          placeholder="请输入"
        />
      </el-descriptions-item>
      <el-descriptions-item v-if="detailObj.isOverdue === '1'">
        <template slot="label">
          待支付逾期费(元)
        </template>
        <el-input
          v-model="detailObj.unpaidOverdueCost"
          disabled
          placeholder="请输入"
        />
      </el-descriptions-item>
      <el-descriptions-item v-if="detailObj.isOverdue === '1'">
        <template slot="label">
          已支付逾期费(元)
          <el-tooltip
            class="item"
            effect="dark"
            content="已支付逾期费=结算周期内提货时支付的逾期费"
            placement="top"
          >
            <i class="icon el-icon-warning" />
          </el-tooltip>
        </template>
        <el-input
          v-model="detailObj.overdueCost"
          disabled
          placeholder="请输入"
        />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          结算差额(元)
          <el-tooltip
            class="item"
            effect="dark"
            content="结算差额=货款差额+本次待支付代采费+待支付逾期费-可抵扣保证金"
            placement="top"
          >
            <i class="icon el-icon-warning" />
          </el-tooltip>
        </template>
        <el-input
          v-model="detailObj.settleDiff"
          disabled
          placeholder="请输入"
        />
      </el-descriptions-item>
      <el-descriptions-item label="">
        <template slot="label">
          结算总金额(元)
          <el-tooltip
            class="item"
            effect="dark"
            content="结算总金额=结算总货款+待支付代采费+已支付代采费+待支付逾期费+已支付逾期费"
            placement="top"
          >
            <i class="icon el-icon-warning" />
          </el-tooltip>
        </template>
        <el-input
          v-model="detailObj.totalSettlementAmount"
          disabled
          placeholder="请输入"
        />
      </el-descriptions-item>
      <el-descriptions-item />
    </el-descriptions>
    <!-- 应收差额 -->
    <h3 v-if="Number(detailObj.settleDiff) !== 0" class="differential">
      应{{ Number(detailObj.settleDiff) > 0 ? "付" : "收" }}差额{{
        Math.abs(detailObj.settleDiff) || 0
      }}元
    </h3>
    <CushionCapitalRecord :id="detailObj.contractId" ref="cushionCapitalRecord" />
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage.vue'
import { agentSettleInfoQueryByDetail } from '@/http/custApi/financialManage'
import CushionCapitalRecord from '@/components/CushionCapitalRecord.vue'
export default {
  components: { DetailsPage, CushionCapitalRecord },
  data() {
    return {
      detailObj: {},
      listData: [],
      detailsItemArr: [
        { label: '经销商', value: 'custCmpName' },
        { label: '代采商', value: 'fundsCmpName' },
        { label: '合作企业', value: 'supplierCmpName' },
        {
          label: '结算状态',
          value: 'settleStatus',
          childAndColor: this.$store.getters.getDictionaryItem('cust_settle_status')
        },
        { label: '结算单号', value: 'settleNo' },
        { label: '结算时间', value: 'settleDate' },
        {
          label: '合同编号',
          value: 'custContractNo',
          path: '/purchaseContractManage/contractInfo',
          queryObj: { id: '' }
        },
        {
          label: '提货记录',
          path: '/takeDeliveryManage/index',
          queryObj: { contractNo: '' },
          leftWords: '查看记录'
        }
        // {
        //   label: '垫资记录',
        //   leftWords: '查看记录',
        //   operator: this.checkAdvanceRecord
        // }
      ]
    }
  },
  mounted() {
    if (this.$route.query.id) {
      agentSettleInfoQueryByDetail(this.$route.query.id, (res) => {
        this.detailObj = { ...res.data }
        // 给要跳转的提货记录进行赋值
        this.detailsItemArr.forEach((item) => {
          if (item.path) {
            item.queryObj.id = this.detailObj.contractId
            item.queryObj.contractNo = this.detailObj.custContractNo
          }
        })
        if (this.detailObj.settleGoodsRespList) {
          this.listData = [...this.detailObj.settleGoodsRespList]
        }
      })
    }
  },
  methods: {
    // 查看垫付记录
    checkAdvanceRecord() {
      this.$refs.cushionCapitalRecord.visible = true
    }
  }
}
</script>

<style lang="scss">
.additionalSettlementPageBox {
  .differential {
    padding: 30px 32px;
  }
  .cancelButton {
    margin-left: 32px;
  }
  .table {
    width: calc(100% - 64px);
    margin-left: 32px;
    margin-bottom: 32px;
    .el-table__header .el-checkbox {
      display: none;
    }
  }
  .confirmationSettlement {
    margin: 0 32px;
    .el-descriptions-item__cell {
      padding-bottom: 24px;
    }
    .el-descriptions-item__content{
      width: 100%;
    }
    .labelClassName {
      flex: 0 0 132px;
      display: flex;
      align-items: center;
    }
    .el-input {
      width: 86%;
    }
    .icon {
      font-size: 14px;
      color: #666;
      margin-left: 12px;
    }
  }

  .el-descriptions {
    padding: 0 !important;
  }
  .settlementTitle {
    display: flex;
    align-items: flex-end;
    margin-bottom: 32px;
    span {
      color: #666;
      font-size: 14px;
      margin-left: 18px;
    }
  }
}
</style>
